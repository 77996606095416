import React from 'react';
import { useAppDispatch } from '../../hooks';
import { Clickable, ClickableVariant } from '../Clickable';
import { Image } from '../Image';
import {
  AVAILABLE_AI_TOKENS,
  AVAILABLE_TICKETS,
  RESERVED_TICKETS,
} from '../../images';
import { OverlayType, setOverlay } from '../../actions';
import { c } from '../../utils';

import classes from './Ticket.module.scss';
import { SALES_PLAN_UPGRADE_URL } from '../../config';
import { trackContactSales } from '../../libs/trackingLib';

const ticketTypeMap = {
  available: {
    title: 'Available Tickets',
    image: AVAILABLE_TICKETS,
    color: 'blue-delight',
    cta: () => {
      const dispatch = useAppDispatch();
      return (
        <Clickable
          onClick={() => setOverlay(dispatch, OverlayType.PURCHASE_TICKETS)}
          variant={ClickableVariant.BUTTON_SECONDARY}
        >
          Buy More
        </Clickable>
      );
    },
  },
  reserved: {
    title: 'Reserved Tickets',
    image: RESERVED_TICKETS,
    color: 'logo-blue',
  },
  aiTokens: {
    title: 'Available AI Tokens',
    image: AVAILABLE_AI_TOKENS,
    color: 'pink-delight',
    cta: () => {
      return (
        <Clickable
          onClick={() => {
            if (window) window.open(SALES_PLAN_UPGRADE_URL, '_blank');
            trackContactSales();
          }}
          variant={ClickableVariant.BUTTON_QUATERNARY}
        >
          Get More
        </Clickable>
      );
    },
  },
};

interface Props {
  ticketType: string;
  ticketCount: number;
}

function Ticket({ ticketType, ticketCount }: Props) {
  const { image, title, color, cta: CTA } = ticketTypeMap[ticketType];
  return (
    <div className={c(classes.Ticket, 'flex flex-col items-center gap-_5')}>
      <h4 className={`subheading bold color-${color}`}>{title}</h4>
      <div className="flex items-center gap-_5 justify-center flex-wrap">
        <div className={c(classes.imageWrapper, 'flex flex-col')}>
          <Image src={image.src} alt={image.alt} />
        </div>
        <h3 className={`title bold color-${color}--dark`}>{ticketCount}</h3>
      </div>

      {CTA && <CTA />}
    </div>
  );
}

export default Ticket;
