import React from 'react';

import { SALES_CONTACT_URL } from '../../config';
import { MessageWithIllustration } from '../MessageWithIllustration';
import { INACTIVE_ANALYTICS as img } from '../../images';

export function ReportNotEnabled() {
  return (
    <MessageWithIllustration
      ctaLabel="Contact Sales"
      ctaTo={SALES_CONTACT_URL}
      heading="Upgrade Required"
      imgAlt={img.alt}
      imgSrc={img.src}
      message="Your current plan does not include access to tracking or analytics.
          Contact a sales representative to get access to in-depth information
          about what went on at your event."
    />
  );
}
