import React, { useCallback, useEffect } from 'react';
import { EmojiObjectsOutlined } from '@material-ui/icons';

import { isPending, isRejected } from '../../utils';
import { findOrCreateUser, OverlayType, setOverlay } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  currentUser,
  currentUserPermissions,
  getStatusOfGetUser,
} from '../../selectors';
import { Toolbar } from '../../components/Toolbar';
import { Tooltip } from '../../components/Tooltip';
import { Tickets } from '../../components/Tickets';
import { Clickable, ClickableVariant } from '../../components/Clickable';
import { PlanBadge, PlanDetails } from '../../components/AccountPlan';

import UserDetailsForm from '../../components/forms/UserDetailsForm';
import { SALES_PLAN_UPGRADE_URL } from '../../config';
import { trackContactSales } from '../../libs/trackingLib';

const PAGE_TITLE = 'Account';

export function AccountPage() {
  const dispatch = useAppDispatch();

  const user = useAppSelector(currentUser);
  const { permissions: plan } = useAppSelector(currentUserPermissions);
  const status = useAppSelector(getStatusOfGetUser);
  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);

  const hasPermissionOverrides =
    Object.values(user?.permissionOverrides ?? {}).length > 0;

  const handleTipsClick = useCallback(
    () => setOverlay(dispatch, OverlayType.ONBOARDING),
    [],
  );
  const handleUpgradeClick = useCallback(() => {
    if (window) window.open(SALES_PLAN_UPGRADE_URL, '_blank');
    trackContactSales();
  }, []);

  useEffect(() => {
    findOrCreateUser(dispatch);
  }, []);

  const fallback = isMakingRequest ? (
    <p className="App-error">Loading...</p>
  ) : (
    <p className="App-error">User not found.</p>
  );

  return (
    <div className="page-inner bg-color-gradient-faded">
      <Toolbar title={PAGE_TITLE} classNames="bg-frosted" />
      <div className="flex-1 flex flex-col lg:flex-row p1 gap-1  page-inner__content">
        {user ? (
          <>
            <div className="flex lg:col-4 flex-col gap-2 bg-color-white shadow-sm br_25 p2">
              <div className="flex gap-1 justify-between">
                <h3 className="color-shade-80 body bold flex-1">My Tickets</h3>
                <Clickable
                  onClick={handleTipsClick}
                  className="flex items-center"
                >
                  <Tooltip content="Onboarding Tips">
                    <div className="flex items-center">
                      <EmojiObjectsOutlined className="color-shade-70 mr_25" />
                      <span className="body">Tips</span>
                    </div>
                  </Tooltip>
                </Clickable>
              </div>
              <div className="flex flex-col lg:items-start">
                <Tickets
                  ticketCounts={{
                    available: user.tickets,
                    reserved: user.reservedTickets,
                    aiTokens: user.aiTokens,
                  }}
                />
              </div>
            </div>
            <div className="flex lg:col-4 flex-col gap-2 bg-color-white shadow-sm br_25 p2">
              <h3 className="color-shade-80 body bold">My Plan</h3>
              {plan && (
                <div className="flex flex-col items-center gap-1">
                  <PlanBadge
                    planName={hasPermissionOverrides ? 'custom' : plan.role}
                  />
                  <PlanDetails />
                  <Clickable
                    onClick={handleUpgradeClick}
                    variant={ClickableVariant.BUTTON_SECONDARY}
                    className="margin-auto mt1"
                  >
                    Upgrade
                  </Clickable>
                </div>
              )}
            </div>
            <div className="flex lg:col-4 xl:col-5 flex-col gap-2 bg-color-white shadow-sm br_25 p2">
              <h3 className="color-shade-80 body bold">My Profile</h3>
              <UserDetailsForm {...user} />
            </div>
          </>
        ) : (
          fallback
        )}
        {hasError && (
          <p className="App-error">Error retrieving current user!</p>
        )}
      </div>
    </div>
  );
}
