import React from 'react';
import { Toolbar } from '../../components/Toolbar';
import { ResourceCard, ResourceCardProps } from './ResourceCard';

const PAGE_TITLE = 'Resources';

const resources: Array<ResourceCardProps> = [
  {
    type: 'Template / Video',
    title: 'Map Customization',
    image:
      'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-maps/_map-base.jpg',
    description:
      'Create your own custom maps with our easy-to-use template! It includes a tutorial video to show you how!',
    ctaLabel: 'Get started',
    ctaLocation:
      'https://docs.google.com/presentation/d/11c209mkLwMSKfW-OOQ6JpY7YuIjn0HQmUynEnNRSSOQ/copy#slide=id.g9072c99268_6_0',
  },
  {
    type: 'Template',
    title: 'Brochure Customization',
    image:
      'https://gatherlyio-website-assets.s3.us-east-2.amazonaws.com/betterDMV/customBrochure.svg',
    description:
      'Not sure how to communicate your event info? Use our template as a starting point.',
    ctaLabel: 'Download Template',
    ctaLocation:
      'https://gatherlyio-website-assets.s3.us-east-2.amazonaws.com/betterDMV/downloads/Gatherly+Brochure+Customization.pptx',
  },
  {
    type: 'Guides',
    title: 'Help Center',
    image:
      'https://gatherlyio-website-assets.s3.us-east-2.amazonaws.com/betterDMV/gatherlyGuide.png',
    description:
      'Learn all about Gatherly in our comprehensive guides designed for event hosts like you.',
    ctaLabel: 'View Guides',
    ctaLocation: 'https://help.gatherly.io/',
  },
  {
    type: 'Videos',
    title: 'Overview Video',
    image:
      'https://gatherlyio-website-assets.s3.us-east-2.amazonaws.com/betterDMV/demoVid.svg',
    description:
      'Watch our overview video to understand how Gatherly might help you with your next online event.',
    ctaLabel: 'Watch Video',
    ctaLocation: 'https://www.youtube.com/watch?v=2GP2OZfSKIo',
  },
  {
    type: 'Videos',
    title: 'Tutorial Playlist',
    image:
      'https://gatherlyio-website-assets.s3.us-east-2.amazonaws.com/betterDMV/tutorialPlaylist.png',
    description:
      'This playlist covers all the important features and is great prep for your guests.',
    ctaLabel: 'View Playlist',
    ctaLocation:
      'https://www.youtube.com/playlist?list=PLos49lOp--NHtwSFn_OPeChxkhjYweyvl',
  },
  {
    type: 'Tech',
    title: 'Check Compatibility',
    image:
      'https://poormanvr.s3.us-east-2.amazonaws.com/tutorial-user-katara.gif',
    description:
      'Send guests here to check their device and network compatibility with Gatherly.',
    ctaLabel: 'Visit Hub',
    ctaLocation: 'https://check.gatherly.io/',
  },
];

export function ResourcesPage() {
  return (
    <div className="page-inner">
      <Toolbar title={PAGE_TITLE} classNames="bg-frosted" />
      <div className="bg-color-gradient-faded page-inner__content flex-1">
        <div className="flex flex-row flex-wrap gap-2 pt1 pb8">
          {resources.map((resource, index) => (
            <ResourceCard {...resource} key={`resource-${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
}
