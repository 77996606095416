import React, { useCallback, useMemo } from 'react';
import { HelpRounded as HelpIcon } from '@material-ui/icons';
import { useTimeout } from 'react-use';

import { setOverlay, OverlayType } from '../../actions';
import { Clickable, ClickableVariant } from '../../components/Clickable';
import { Tooltip } from '../../components/Tooltip';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { currentUser } from '../../selectors';
import { c } from '../../utils';

import classes from './TicketsIndicator.module.scss';

type Props = {
  className?: string;
};
export function TicketsIndicator({ className }: Props) {
  const dispatch = useAppDispatch();
  const onClickHelp = useCallback(() => {
    setOverlay(dispatch, OverlayType.ONBOARDING);
  }, []);
  const onClickBuyTickets = useCallback(() => {
    setOverlay(dispatch, OverlayType.PURCHASE_TICKETS);
  }, []);

  const user = useAppSelector(currentUser);

  const [didLoad] = useTimeout(200);
  const percent = useMemo(() => {
    if (!user) return 0;
    if (!didLoad) return 0;
    const { reservedTickets = 0, tickets = 1 } = user || {};
    return (100 * reservedTickets) / (tickets + reservedTickets);
  }, [didLoad, user?.reservedTickets, user?.tickets]);

  return (
    <div
      className={c(
        classes.TicketsIndicator,
        className,
        'flex flex-col bg-color-white shadow-sm br_25 py_5 px_75',
      )}
    >
      <div className="flex items-center justify-between mb_5 gap-1">
        <p>
          <span className="detail uppercase bold color-shade-60">Tickets</span>
          <Tooltip className="small" content="Onboarding tips">
            <Clickable
              onClick={onClickHelp}
              className="color-shade-50 hover:color-shade-70 transition-color ml_25"
            >
              <HelpIcon className={c(classes.icon)} />
            </Clickable>
          </Tooltip>
        </p>
        <Clickable
          className="border-1 border-color-shade-10"
          onClick={onClickBuyTickets}
          variant={ClickableVariant.BUTTON_SMALL}
        >
          Buy Tickets
        </Clickable>
      </div>
      <div className={c(classes.bar, 'flex bg-color-blue-delight')}>
        <div
          className="py_25 bg-color-shade-70 transition-width"
          style={{ width: `${percent}%` }}
        />
      </div>
      <div className="flex justify-between gap-2">
        <div className="flex flex-col">
          <strong className="detail">{user?.reservedTickets || 0}</strong>
          <span className="small">Reserved</span>
        </div>

        <div className="flex flex-col items-end">
          <strong className="detail">{user?.tickets || 0}</strong>
          <span className="small">Available</span>
        </div>
      </div>
    </div>
  );
}
