import { Clickable, ClickableVariant } from '../Clickable';
import errorCreatingAiEvent from '../../assets/error-creating-ai-event.png';

import classes from './ErrorCreatingAIEventDialog.module.scss';
import { classnames } from '@gatherly/lib';
import { Modal } from '@material-ui/core';

export const ErrorCreatingAIEventDialog = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  return (
    <Modal
      className={classnames(
        classes.ErrorCreatingAiEventDialog,
        'flex justify-center items-center p1',
      )}
      open={true}
    >
      <>
        <div
          className={classnames(
            'flex flex-col items-center justify-center gap-1 p1 bg-color-white br_25 shadow-md',
            classes.contentContainer,
          )}
        >
          <img
            className=""
            src={errorCreatingAiEvent}
            alt="Error creating AI event"
          />
          <p className="p1 body">We had some trouble generating your event.</p>
          <div
            className={classnames(
              'flex flex-col justify-center align-center gap-1 mb1',
              classes.errorContent,
            )}
          >
            <p>
              None of your AI token were used. Please try again in a few minutes
              or adjust the prompt.
            </p>
            <p>
              If problems persist, you can opt to set your event up manually.
            </p>
          </div>

          <div>
            <Clickable
              variant={ClickableVariant.BUTTON_TERTIARY}
              onClick={handleClose}
            >
              Try again
            </Clickable>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default ErrorCreatingAIEventDialog;
