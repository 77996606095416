import React from 'react';

import { Footer } from '../../components/Footer';
import { Image } from '../../components/Image';
import { SignInForm } from '../../components/forms';
import { Images } from '../../images';
import { c } from '../../utils';
import classes from './HomePage.module.scss';

export function HomePage() {
  return (
    <div className={c('page-inner', classes.HomePage)}>
      <Image
        src={Images.MAP_BREAK_ROOM.src}
        alt={Images.MAP_BREAK_ROOM.alt}
        className="flex flex-1 flex-col justify-center pb1"
        isBackground
      >
        <div className="flex flex-1 flex-col md:flex-row-reverse">
          <div className="flex flex-col flex-1 md:col-6 p1 md:p0">
            <div className="flex flex-col flex-1 items-center justify-center">
              <SignInForm />
            </div>
          </div>
          <div className="flex flex-col justify-center md:col-6">
            <div className="hidden md:visible flex-1">
              <div className="flex flex-col">
                <span
                  className={c(
                    classes.huddleSixteen,
                    'bg-color-logo-green--dark color-white',
                  )}
                >
                  16
                </span>
              </div>
              <div className="flex flex-col">
                <span
                  className={c(
                    classes.huddleTwo,
                    'bg-color-rose color-burgundy border-color-burgundy',
                  )}
                >
                  2
                </span>
              </div>
              <div className="flex flex-col">
                <span
                  className={c(
                    classes.huddleThree,
                    'bg-color-rose color-burgundy border-color-burgundy',
                  )}
                >
                  3
                </span>
              </div>
              <div className="flex flex-col">
                <span
                  className={c(
                    classes.huddleFive,
                    'bg-color-leaf-green color-logo-green--dark border-color-logo-green--dark',
                  )}
                >
                  5
                </span>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Image>
    </div>
  );
}
