import React from 'react';
import { Clear as ClearIcon, Check as CheckIcon } from '@material-ui/icons';
import { AclPermissionsRole, Permissions } from '@eventmanager/types';

import { Tooltip } from '../Tooltip';
import { useAppSelector } from '../../hooks';
import { currentUserPermissions, getPermission } from '../../selectors';
import { currency, c } from '../../utils';

const boolPrefix = (isEnabled: boolean) => (!isEnabled ? 'No ' : '');
const formatTime = (value: number) =>
  value < 1 ? `${Math.floor(value * 60)} minute` : `${value} hour`;

const planDetails: Partial<
  Record<keyof Permissions, (value: any, role?: AclPermissionsRole) => string>
> = {
  aiGeneratedEvents: (value: number) => {
    return `${value !== 0 ? '' : value} AI Generated Events`;
  },
  ticketCost: (value: number) =>
    value === -1
      ? '∞ Additional Tickets'
      : `${currency(value)} per Additional Ticket`,
  maxFloors: (value: number) =>
    `${value === -1 ? '∞' : value} Floors per Event`,
  maxEventDuration: (value: number) =>
    `${value === -1 ? '∞' : formatTime(value)} Max Event Duration`,
  customMaps: (isEnabled: boolean) => `${boolPrefix(isEnabled)}Custom Maps`,
  logoCustomization: (isEnabled: boolean) =>
    `${boolPrefix(isEnabled)}Logo Customization`,
  broadcastRecordings: (isEnabled: boolean) =>
    `${boolPrefix(isEnabled)}Download broadcast recordings`,
  dataAnalytics: (isEnabled: boolean) =>
    `${boolPrefix(isEnabled)}Data Analytics`,
  attendeeTracking: (trackingId: string) =>
    `${boolPrefix(!!trackingId)}Attendee Tracking`,
};

function getPlanDetails(
  role: AclPermissionsRole,
  permissions: Permissions,
  filter: string[],
  overridden = [] as string[],
): PlanDetailProps[] {
  const shownKeys = Object.keys(planDetails).filter(
    key => !filter.includes(key),
  );
  return shownKeys.map(key => {
    const value = permissions[key];
    return {
      key,
      isActive: !!value,
      isOverride: overridden.includes(key),
      text: planDetails[key](value, role),
    };
  });
}

interface PlanDetailProps {
  key: string;
  isActive: boolean;
  isOverride?: boolean;
  text: string;
}

function PlanDetail({ isActive, isOverride, text }: PlanDetailProps) {
  const Icon = isActive ? CheckIcon : ClearIcon;
  return (
    <li
      className={c(
        'color-shade-80 flex items-center p_25',
        !isActive && 'opacity-0_5',
      )}
    >
      <Icon className="mr1" />
      {text}
      {isOverride && (
        <Tooltip className="detail" content="overridden">
          <span className="ml_25 cursor-pointer">*</span>
        </Tooltip>
      )}
    </li>
  );
}

interface PlanDetailsProps {
  role?: AclPermissionsRole;
  filter?: string[];
}

export function PlanDetails({ role, filter = [] }: PlanDetailsProps) {
  const { permissions, overridden } = role
    ? {
        permissions: useAppSelector(getPermission(role)),
        overridden: [] as string[],
      }
    : useAppSelector(currentUserPermissions);
  if (!permissions) return null;
  const details = getPlanDetails(
    permissions.role,
    permissions,
    filter,
    overridden,
  );
  return (
    <ul className="list-style-none detail">
      {details.map(({ isActive, isOverride, text }, index) => (
        <PlanDetail
          key={`plan-${role}-detail-${index}`}
          isActive={isActive}
          isOverride={isOverride}
          text={text}
        />
      ))}
    </ul>
  );
}
