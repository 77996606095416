import { Amplify, Hub } from 'aws-amplify';
import { useEffect, useMemo } from 'react';
import { Route, Switch, useLocation, useRoute } from 'wouter';
import {
  EMPTY_USER,
  fetchPermissions,
  findOrCreateUser,
  syncAuthState,
} from './actions';
import {
  AWS_REGION,
  COGNITO_DOMAIN_NAME,
  IDENTITY_POOL_ID,
  REDIRECT_SIGNIN_URL,
  REDIRECT_SIGNOUT_URL,
  USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID,
} from './config';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  identifyUser,
  initTracker,
  trackAccountCreated,
  trackAccountVerified,
  trackPage,
} from './libs/trackingLib';
import {
  currentUserAuthState,
  getStatusOfGetPermissions,
  getStatusOfGetUser,
} from './selectors';
import { c, isIdle } from './utils';
import {
  clearCurrentSession,
  initiateSignIn,
  setSignedInUserSession,
} from './utils/auth';

import { Overlay } from './components/Overlay';
import { Sidebar } from './components/Sidebar';
import { UserAuthState } from './enums';
import { AccountPage } from './pages/AccountPage';
import { CallbackPage } from './pages/CallbackPage';
import { EventDetailPage } from './pages/EventDetailPage';
import { EventsPage } from './pages/EventsPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { HomePage } from './pages/HomePage';
import { ResourcesPage } from './pages/ResourcesPage';
import { SignUpPage } from './pages/SignUpPage';
import { VerificationPage } from './pages/VerificationPage';

function App() {
  const [location, setLocation] = useLocation();
  const [isHome] = useRoute('/');
  const [isForgotPassword] = useRoute('/forgot-password');
  const [isVerifying] = useRoute('/verify');
  const [isSigningUp] = useRoute('/sign-up');
  const [isCallback] = useRoute('/callback');
  const dispatch = useAppDispatch();

  const authState = useAppSelector(currentUserAuthState);
  const statusOfGetPermissions = useAppSelector(getStatusOfGetPermissions);
  const statusOfGetUser = useAppSelector(getStatusOfGetUser);

  const isPublicRoute = useMemo(() => {
    return (
      isVerifying || isSigningUp || isCallback || isForgotPassword || isHome
    );
  }, [isVerifying, isSigningUp, isForgotPassword, isHome, isCallback]);
  const showSidebar = !isPublicRoute;

  Amplify.configure({
    Auth: {
      identityPoolId: IDENTITY_POOL_ID,
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
      region: AWS_REGION,
      oauth: {
        domain: COGNITO_DOMAIN_NAME,
        redirectSignIn: REDIRECT_SIGNIN_URL,
        redirectSignOut: REDIRECT_SIGNOUT_URL,
        responseType: 'code',
      },
    },
  });

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'autoSignIn':
        case 'signIn':
          // This is a hack to check if the Google user is signing in for the first time, using the dateCreated field
          if (
            data?.signInUserSession?.idToken?.payload?.identities?.[0]
              ?.providerType === 'Google' &&
            data?.signInUserSession?.idToken?.payload?.identities?.[0]
              ?.dateCreated >=
              new Date().getTime() - 1000 * 5
          ) {
            identifyUser({
              ...EMPTY_USER,
              userId: data?.signInUserSession?.idToken?.payload?.sub,
              email: data?.signInUserSession?.idToken?.payload?.email,
              firstName: data?.signInUserSession?.idToken?.payload?.given_name,
              lastName: data?.signInUserSession?.idToken?.payload?.family_name,
            });
            trackAccountCreated({ method: 'google' });
            trackAccountVerified({ method: 'google' });
          }

          setSignedInUserSession().then(isSet => {
            if (isSet) {
              setLocation('/events');
              window.location.reload();
              return;
            }

            setLocation('/');
          });
          break;
        case 'signOut':
          clearCurrentSession();
          break;
        case 'signIn_failure':
          initiateSignIn(data?.message);
          break;
        case 'autoSignIn_failure':
          setLocation('/');
          break;
      }

      return unsubscribe;
    });
  }, []);

  useEffect(() => {
    syncAuthState(dispatch);
    initTracker();
  }, []);

  useEffect(() => {
    trackPage();
  }, [location]);

  useEffect(() => {
    // redirects
    switch (authState) {
      case UserAuthState.LOGGED_IN: {
        if (isPublicRoute) setLocation('/events');
        break;
      }
      case UserAuthState.UNVERIFIED: {
        if (!isVerifying) setLocation('/verify');
        break;
      }
      case UserAuthState.LOGGED_OUT: {
        if (isPublicRoute) break;
        setLocation('/');
        break;
      }
    }
  }, [authState, isPublicRoute, isVerifying, setLocation]);

  useEffect(() => {
    if (authState !== UserAuthState.LOGGED_IN) return;

    // initial data requests
    if (isIdle(statusOfGetUser)) {
      findOrCreateUser(dispatch);
    }
    if (isIdle(statusOfGetPermissions)) {
      fetchPermissions(dispatch);
    }
  }, [authState, isHome, statusOfGetUser, statusOfGetPermissions, dispatch]);

  return (
    <div className="App bg-color-shade-10">
      {showSidebar && <Sidebar />}
      <div
        className={c(
          !!showSidebar ? 'page--withSidebar' : 'page',
          'flex flex-col flex-1',
        )}
      >
        <Switch>
          <Route path="/account">
            <AccountPage />
          </Route>
          <Route path="/events">
            <EventsPage />
          </Route>
          <Route path="/events/:id/:tab*">
            <EventDetailPage />
          </Route>
          <Route path="/forgot-password">
            <ForgotPasswordPage />
          </Route>
          <Route path="/resources">
            <ResourcesPage />
          </Route>
          <Route path="/sign-up">
            <SignUpPage />
          </Route>
          <Route path="/verify">
            <VerificationPage />
          </Route>
          <Route path="/callback">
            <CallbackPage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
          <Route path="/:rest*">
            <p>Not Found</p>
          </Route>
        </Switch>
        <Overlay />
      </div>
    </div>
  );
}

export default App;
